@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

body,
html {
	height: 100%;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loader {
	/* border: 8px solid #f3f3f3; */
	/* border-top: 8px solid #69d08d; */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}
