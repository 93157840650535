@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap');
@import './mixins.scss';
@import './tailwind.scss';
@import './App.scss';
@import './colors.scss';
@import './hunter-tour.scss';

//////// Dark Mode Colors ///////
$darkMode-black-color: #0f121d;
$darkMode-grey-color: #1d202b;
$darkMode-light-grey-color: #52586d;
$darkMode-white-color: #ffff;
$darkMode-ash-color: #848aa0;
$darkMode-green-color: #84c70d;
$darkMode-green-color-10: rgba(132, 199, 13, 0.1);
$darkMode-green-color-20: rgba(132, 199, 13, 0.2);
$darkMode-green-color-900: #2c4204;
$darkMode-orange-color: #ff8b3e;
$darkMode-orange-color-9: rgba(255, 139, 62, 0.09);
$darkMode-orange-color-10: rgba(255, 139, 62, 0.1);
$darkMode-orange-color-20: rgba(255, 139, 62, 0.2);
$darkMode-orange-color-30: rgba(255, 139, 62, 0.3);
$darkMode-red-color-10: rgba(240, 68, 56, 0.1);
$darkMode-red-color-20: rgba(240, 68, 56, 0.2);
$darkMode-red-color: #f04438;

////light mode colors ///////
$lightMode-light-color: #f7f7f7; //
$lightMode-pink-color: pink;
$lightMode-black-color: #0f121d;
$lightMode-gray-color: #1d202b;
$darkMode-ash-color: #848aa0;
$darkMode-green-color: #84c70d;
$darkMode-orange-color: #ff8b3e;

:root {
	color-scheme: light only;
}

.luckiest-guy-regular {
	font-family: 'Luckiest Guy', cursive;
	font-weight: 400;
	font-style: normal;
}

@media (prefers-color-scheme: dark) {
	/* Styles for dark mode */
	:root {
		color-scheme: light;
	}
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-style: normal;
	position: relative;
	font-family: 'OpenRunde', sans-serif;
	font-weight: 400;
	// font-family: 'Space Grotesk', sans-serif;

	////// defaul mode - dark //////
	&.dark-mode {
		.bg {
			&-primary {
				background-color: $darkMode-black-color;
			}
			&-secondary {
				background-color: $darkMode-grey-color;
			}
			&-tertiary {
				background-color: $darkMode-white-color;
			}
			&-other {
				background-color: $darkMode-light-grey-color;
			}
		}
		.btn {
			&-primary {
				background-color: $darkMode-green-color;
			}
			&-primary:hover {
				background-color: $darkMode-green-color-900;
			}
			&-primary:disabled {
				background-color: $darkMode-green-color-900;
			}
			&-primary-10 {
				background-color: $darkMode-green-color-10;
			}
			&-primary-20 {
				background-color: $darkMode-green-color-20;
			}
			&-secondary {
				background-color: $darkMode-orange-color;
			}
			&-secondary-9 {
				background-color: $darkMode-orange-color-9;
			}
			&-secondary-10 {
				background-color: $darkMode-orange-color-10;
			}
			&-secondary-20 {
				background-color: $darkMode-orange-color-20;
			}
			&-secondary-30 {
				background-color: $darkMode-orange-color-30;
			}
			&-tertiary {
				background-color: $darkMode-grey-color;
			}
			&-red-10 {
				background-color: $darkMode-red-color-10;
			}
			&-red-20 {
				background-color: $darkMode-red-color-20;
			}
		}
		.text {
			&-primary {
				color: $darkMode-white-color;
			}
			&-secondary {
				color: $darkMode-ash-color;
			}
			&-tertiary {
				color: $darkMode-green-color;
			}
			&-danger {
				color: $darkMode-orange-color;
			}
			&-red {
				color: $darkMode-red-color;
				&-other {
					color: $darkMode-black-color;
				}
			}
		}

		/////// light mode //////
		&.light-mode {
			.bg {
				&-primary {
					background-color: $lightMode-light-color;
				}
				&-secondary {
					background-color: $lightMode-pink-color;
				}
				&-tertiary {
					background-color: $darkMode-black-color;
				}
			}
			.btn {
				&-primary {
					background-color: $darkMode-orange-color;
				}
				&-secondary {
					background-color: $darkMode-grey-color;
				}
				&-tertiary {
					background-color: $darkMode-green-color;
				}
			}
			.text {
				&-primary {
					color: $darkMode-black-color;
				}
				&-secondary {
					color: brown;
				}
				&-tertiary {
					color: gold;
				}
				&-danger {
					color: blue;
				}
			}
		}
	}
}

/* Define a floating zigzag animation */
@keyframes floating {
	0% {
		transform: translateY(0) translateX(0);
	}
	25% {
		transform: translateY(-30px) translateX(-10px);
	}
	50% {
		transform: translateY(-60px) translateX(20px);
	}
	75% {
		transform: translateY(-30px) translateX(-10px);
	}
	100% {
		transform: translateY(-100px) translateX(0);
	}
}

.floating-banner {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	animation: floating 5s ease-in-out infinite; /* Adjust timing and easing */
	transition: opacity 0.5s ease-in-out;
}

.banner-content {
	background-color: rgba(255, 255, 255, 0.8);
	padding: 10px;
	border-radius: 5px;
	font-size: 18px;
	color: #000;
}
