@font-face {
	font-family: 'vag_rounded';
	font-style: normal;
	font-weight: normal;
	src:
		local('vag_rounded'),
		url(../fonts/vag_rounded_bt.ttf) format('truetype');
}

@font-face {
	font-family: 'OpenRunde';
	font-style: normal;
	font-weight: bold;
	src:
		local('OpenRunde'),
		url(../fonts/OpenRunde/OpenRunde-Bold.otf) format('opentype');
}

@font-face {
	font-family: 'OpenRunde';
	font-style: normal;
	font-weight: 500;
	src:
		local('OpenRunde'),
		url(../fonts/OpenRunde/OpenRunde-Medium.otf) format('opentype');
}
@font-face {
	font-family: 'OpenRunde';
	font-style: normal;
	font-weight: 400;
	src:
		local('OpenRunde'),
		url(../fonts/OpenRunde/OpenRunde-Regular.otf) format('opentype');
}
@font-face {
	font-family: 'OpenRunde';
	font-style: normal;
	font-weight: 600;
	src:
		local('OpenRunde'),
		url(../fonts/OpenRunde/OpenRunde-Semibold.otf) format('opentype');
}

@mixin screen($size) {
	@if ($size == ('mac-13')) {
		@media screen and (max-width: 1440px) {
			@content;
		}
	}
	@if ($size == 'x-large') {
		@media screen and (max-width: 1280px) {
			@content;
		}
	}
	@if ($size == 'large') {
		@media screen and (max-width: 1024px) {
			@content;
		}
	}
	@if ($size == 'medium') {
		@media screen and (max-width: 991px) {
			@content;
		}
	}
	@if ($size == 'inbetween') {
		@media screen and (max-width: 767.99px) {
			@content;
		}
	} @else if($size == 'small') {
		@media screen and (max-width: 576px) {
			@content;
		}
	} @else if($size == 'x-small') {
		@media screen and (max-width: 414px) {
			@content;
		}
	} @else if($size == $size) {
		@media screen and (max-width: $size) {
			@content;
		}
	}
}
