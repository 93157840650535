@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-main {
	@apply rounded-[2.5rem] py-1 px-4 cursor-pointer capitalize text-[13px] font-normal box-border;
}

.util-btn {
	@apply cursor-pointer flex items-center capitalize;
}
